import { useEffect, useState } from "react";

import { Col, Divider, Row, Typography } from "antd";

import axiosInstance from "./axiosInstance";

const Home = () => {
  const [lastTransactions, setLastTransactions] = useState([]);
  const [homeData, setHomeData] = useState<any>(null);

  useEffect(() => {
    const fetchLastTransactions = async () => {
      const response = await axiosInstance.get(
        "https://melcarnedirectus2.digipulse.ch/items/transactions_fao?meta=*&fields[]=Source&page=1&limit=2&filter[Type][_in]=Achat&filter[status][_in]=SVB_Estimee,SVB_Verifiee&sort=-Date_Transaction"
      );

      setLastTransactions(response.data.data);
    };

    fetchLastTransactions();
  }, [setLastTransactions]);

  useEffect(() => {
    const fetchHomeData = async () => {
      const response = await axiosInstance.get(
        "https://melcarnebackend.digipulse.ch/stats_page_accueil"
      );

      setHomeData(response.data);
    };

    fetchHomeData();
  }, [setHomeData]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        marginTop: 40,
      }}
    >
      <div
        style={{
          maxWidth: 1200,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Row>
          <Col style={{ padding: "0 16px" }} span={12}>
            <Row>
              <Typography.Title level={4}>
                Transactions du{" "}
                {new Intl.DateTimeFormat("fr").format(new Date())}
              </Typography.Title>
            </Row>
            {lastTransactions.length > 1 && (
              <Row style={{ marginBottom: 24 }}>
                <Typography.Text>
                  {lastTransactions[0]["Source"]}
                </Typography.Text>
                <Divider style={{ margin: "16px 0" }} />
                <Typography.Text>
                  {lastTransactions[1]["Source"]}
                </Typography.Text>
              </Row>
            )}

            <Row>
              <Typography.Title level={4}>
                Nombre de transaction
              </Typography.Title>
            </Row>
            {homeData && (
              <Row
                style={{
                  marginBottom: 24,
                  display: "flex",
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#e0e0e0",
                    height: 56,
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text style={{ fontSize: 14 }}>
                    {`${homeData["nb_trans_month"]
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} / mois`}
                  </Typography.Text>
                </div>
                <div
                  style={{
                    backgroundColor: "#e0e0e0",
                    height: 56,
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text style={{ fontSize: 14 }}>
                    {`${homeData["nb_trans_quarter"]
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} / trimestre`}
                  </Typography.Text>
                </div>
                <div
                  style={{
                    backgroundColor: "#e0e0e0",
                    height: 56,
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text style={{ fontSize: 14 }}>
                    {`${homeData["nb_trans_year"]
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} / an`}
                  </Typography.Text>
                </div>
              </Row>
            )}
            <Row>
              <Typography.Title level={4}>Volume des ventes</Typography.Title>
            </Row>
            {homeData && (
              <Row
                style={{
                  marginBottom: 24,
                  display: "flex",
                  flexWrap: "nowrap",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#e0e0e0",
                    height: 56,
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text style={{ fontSize: 14 }}>
                    {`${homeData["volume_month"]
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} / mois`}
                  </Typography.Text>
                </div>
                <div
                  style={{
                    backgroundColor: "#e0e0e0",
                    height: 56,
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text style={{ fontSize: 14 }}>
                    {`${homeData["volume_quarter"]
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} / trimestre`}
                  </Typography.Text>
                </div>
                <div
                  style={{
                    backgroundColor: "#e0e0e0",
                    height: 56,
                    width: "30%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography.Text style={{ fontSize: 14 }}>
                    {`${homeData["volume_year"]
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, " ")} / an`}
                  </Typography.Text>
                </div>
              </Row>
            )}
          </Col>
          <Col span={12}>
            <Row>
              <Typography.Title level={5}>
                {"Qu'est-ce qui se passe sur le marché?"}
              </Typography.Title>
            </Row>
            <Row>
              <img width="100%" src="/map-coming-soon.png" />
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Home;
