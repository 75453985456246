import { FC, Key, ReactNode } from "react";

import {
  FilterOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { Badge, Button, Flex, Input, Popover, Tag, Tooltip } from "antd";
import { isEmpty, omit, omitBy } from "lodash";
import { useNavigate } from "react-router-dom";

import TransactionFaoActions from "./actions/TransactionFaoActions";
import TransactionUpdateActions from "./actions/TransactionUpdateActions";
import { useQueryParamsToState } from "./hooks/useQueryParamsToState";

const collectionActions: Record<string, (props: any) => ReactNode> = {
  transactions_updates: (props: any) => <TransactionUpdateActions {...props} />,
  transactions_fao: (props: any) => <TransactionFaoActions {...props} />,
};

const CollectionTableHeader: FC<{
  collectionId: string;
  selectedRowKeys: Key[];
  admin: boolean;
}> = ({ collectionId, selectedRowKeys, admin }) => {
  const {
    sort,
    filters,
    setFilters,
    setSort,
    toQueryParamsUrl,
    search,
    setSearch,
    pagination,
    setPagination,
  } = useQueryParamsToState();
  const navigate = useNavigate();

  return (
    <Flex gap={16}>
      <Input.Search
        placeholder="Rechercher..."
        onSearch={(value) => {
          setSearch(value);
          setPagination(1, pagination.limit);
          navigate(`?${toQueryParamsUrl()}`);
        }}
        allowClear
        style={{ width: 250 }}
        defaultValue={search.keywords}
        status={search.keywords ? "warning" : undefined}
      />

      <Badge dot={Object.keys(filters).length > 0}>
        <Popover
          title="Filtres"
          trigger="click"
          content={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: 285,
                justifyContent: "space-between",
              }}
            >
              {Object.keys(filters).map((field) => (
                <ul
                  key={field}
                  style={{
                    display: "flex",
                    marginBottom: 8,
                    flexWrap: "wrap",
                  }}
                >
                  <span style={{ marginBottom: 4, width: "100%" }}>
                    {field}:&nbsp;
                  </span>
                  {filters[field].map((value) => (
                    <li key={value} style={{ marginBottom: 4 }}>
                      <Tag
                        key={value}
                        closable
                        onClose={() => {
                          const newFilters = {
                            ...filters,
                            [field]: filters[field].filter((f) => f !== value),
                          };
                          setFilters(omitBy(newFilters, isEmpty));
                          navigate(`?${toQueryParamsUrl()}`);
                        }}
                      >
                        {value.length > 20 ? `${value.slice(0, 20)}...` : value}
                      </Tag>
                    </li>
                  ))}
                </ul>
              ))}

              <Button
                size="small"
                style={{ marginTop: 12 }}
                onClick={() => {
                  setFilters({});
                  navigate(`?${toQueryParamsUrl()}`);
                }}
              >
                Tout supprimer
              </Button>
            </div>
          }
        >
          <Tooltip title="Filtres">
            <Button
              disabled={Object.keys(filters).length === 0}
              icon={<FilterOutlined />}
            />
          </Tooltip>
        </Popover>
      </Badge>

      <Badge dot={Object.keys(sort).length > 0}>
        <Popover
          title="Tris"
          trigger="click"
          content={
            <div style={{ width: 300 }}>
              <div>
                {Object.keys(sort).map((field) => (
                  <Tag
                    style={{ marginBottom: 4 }}
                    key={field}
                    closable
                    onClose={() => {
                      setSort(omit(sort, field));
                      navigate(`?${toQueryParamsUrl()}`);
                    }}
                    icon={
                      sort[field] === "ascend" ? (
                        <SortAscendingOutlined />
                      ) : (
                        <SortDescendingOutlined />
                      )
                    }
                  >
                    {field}
                  </Tag>
                ))}
              </div>

              <Button
                size="small"
                style={{ marginTop: 12, width: "100%" }}
                onClick={() => {
                  setSort({});
                  navigate(`?${toQueryParamsUrl()}`);
                }}
              >
                Tout supprimer
              </Button>
            </div>
          }
        >
          <Tooltip title="Tris">
            <Button
              disabled={Object.keys(sort).length === 0}
              icon={<SortAscendingOutlined />}
            />
          </Tooltip>
        </Popover>
      </Badge>

      {admin && (
        <Button
          onClick={() => {
            localStorage.removeItem("x-fields");
            window.location.href = `/collections/${collectionId}`;
          }}
        >
          Reinitialiser les champs
        </Button>
      )}

      {collectionActions[collectionId]?.({ selectedRowKeys })}
    </Flex>
  );
};

export default CollectionTableHeader;
